/** @jsx jsx */
import { jsx, css } from '@emotion/core';

const ChatQuickReplies = ({ quickreply, children, className, onButtonClick, config }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        flex-shrink: 0;
        overflow-x: hidden;
        margin: 0px 1.5em 1rem;
        margin-left: 0px;
      `}
    >
      {children}
      {quickreply.map((qr, index) => (
        <ChatQuickReplyButton {...qr} key={`ChatQR_${index}`} onButtonClick={onButtonClick} config={config} />
      ))}
    </div>
  );
};

export const ChatQuickReplyButton = ({ content_type, title, payload, image_url, onButtonClick, config }) => {
  switch (content_type) {
    case 'text':
      return (
        <div
          css={theme => css`
            &:first-child {
              margin-left: 0 !important;
            }
            display: flex;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
            min-width: max-content;
            border-width: 1px;
            border-style: solid;
            color: ${theme.chat.agent.color};
            background-color: ${theme.chat.agent.textColor};
            border-image: initial;
            border-radius: 5px;
            margin: 0px 1em 1em 0px;
            padding: 0px 1rem;
            &:hover {
              background-color: ${theme.chat.agent.color};
              color: ${theme.chat.agent.textColor};
            }
          `}
          onClick={() =>
            onButtonClick({
              variables: {
                chatUserId: config.user.id || localStorage.getItem('chatUserId'),
                channelId: config.channel.id,
                message: title,
                payload: payload,
              },
            })
          }
        >
          {image_url && (
            <img
              css={css`
                height: 10px;
                width: 10px;
                align-self: center;
                justify-self: center;
              `}
              alt={'icon'}
              src={image_url}
            />
          )}
          {title}
        </div>
      );
    case 'location':
      return (
        <div
          css={theme => css`
            &:first-child {
              margin-left: 0 !important;
            }

            display: flex;
            color: ${theme.chat.agent.textColor};
            background-color: ${theme.chat.agent.color};
            border-radius: 5px;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
            margin-left: 1rem;
            padding: 0 1rem;
            min-width: max-content;
            &:hover {
              background-color: white;
              color: ${theme.chat.agent.color};
            }
          `}
          onClick={e => {
            navigator.geolocation.getCurrentPosition(pos => {
              onButtonClick('Location', {
                location: {
                  lat: pos.coords.latitude,
                  long: pos.coords.longitude,
                },
                caption: 'Location',
              });
            });
          }}
        >
          Location
        </div>
      );
    default:
      return (
        <div
          css={theme => css`
            &:first-child {
              margin-left: 0 !important;
            }

            display: flex;
            color: ${theme.chat.agent.color};
            background-color: rgba(91, 165, 255, 0.08);
            color: ${theme.chat.agent.color};
            border-radius: 5px;
            text-align: center;
            line-height: 30px;
            cursor: pointer;
            margin-left: 1rem;
            padding: 0 1rem;
            min-width: max-content;
            &:hover {
              background-color: white;
            }
          `}
          onClick={() =>
            onButtonClick({
              variables: {
                chatUserId: config.user.id || localStorage.getItem('chatUserId'),
                channelId: config.channel.id,
                message: title,
                payload: payload,
              },
            })
          }
        >
          {title}
        </div>
      );
  }
};

export default ChatQuickReplies;
