/** @jsx jsx */
import { Mutation } from '@apollo/client/react/components';
import { jsx, keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import gql from 'graphql-tag';
import { Fragment, useState } from 'react';
import { ReactComponent as ClipIcon } from '../../assets/icons/clip.svg';

const MaxNotification = styled.div`
  display: ${({ notification }) => (notification ? 'block' : 'none')};
  position: absolute;
  bottom: 3rem;
  background: #ff0000aa;
  width: max-content;
  right: 15px;
  padding: 0.5rem;
  border: 1px solid red;
  border-radius: 5px;
  color: white;
`;

const Spinner = styled.div`
  width: 26px;
  height: 26px;
  position: relative;
`;

const keyBounce = keyframes`
   0%, 100% { 
    transform: scale(0.0);
   
  } 50% { 
    transform: scale(1.0);
   
  }
`;

const Bounce1 = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #888686;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  animation: ${keyBounce} 2s infinite ease-in-out;
`;

const Bounce2 = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  animation: ${keyBounce} 2s infinite ease-in-out;
  animation-delay: -1s;
`;

const UPLOAD_FILE = gql`
  mutation uploadFile($file: Upload!, $channelId: String!, $chatUserId: String!) {
    uploadFile(file: $file, channelId: $channelId, chatUserId: $chatUserId)
  }
`;

const Label = styled.label`
  cursor: pointer;
  border: none;
  padding: 0;
`;
const Input = styled.input`
  display: none;
`;

export default ({ config }) => {
  const [notification, SetNotification] = useState('');
  const handleChooseFile = (
    {
      target: {
        validity,
        files: [file],
      },
    },
    uploadFile,
    SetNotification
  ) => {
    //Check File here
    if (!file) return;
    if (file.size > 26214400) {
      SetNotification('Max 25mb');
      setTimeout(() => SetNotification(''), 2500);
      return;
    }
    //Upload File
    uploadFile({
      variables: {
        file,
        channelId: config.channel.id,
        chatUserId: config.user.id || localStorage.getItem('chatUserId'),
      },
    });
    file = null;
  };

  if (!config.enableUpload) return null;

  return (
    <Fragment>
      <MaxNotification onClick={() => SetNotification('')} notification={notification}>
        {notification}
      </MaxNotification>
      <Label>
        <Mutation mutation={UPLOAD_FILE}>
          {(uploadFile, { loading }) => (
            <Fragment>
              <Input
                disabled={loading}
                type="file"
                accept="image/*,video/*,audio/*,.pdf,.xls,.doc,.docx,.txt,.zip,.rar"
                onChange={e => handleChooseFile(e, uploadFile, SetNotification)}
              />
              {!loading ? (
                <ClipIcon
                  css={{
                    color: '#6a6a6a',
                    opacity: '0.5',
                    ':hover': { opacity: 1 },
                    transition: 'opacity 0.15s linear',
                  }}
                />
              ) : (
                <Spinner>
                  <Bounce1 />
                  <Bounce2 />
                </Spinner>
              )}
            </Fragment>
          )}
        </Mutation>
      </Label>
    </Fragment>
  );
};
