export default (onButtonClick, button, config) => {
  switch (button.type) {
    case 'postback':
      onButtonClick({
        variables: {
          chatUserId: config.user.id || localStorage.getItem('chatUserId'),
          channelId: config.channel.id,
          message: button.title,
          payload: button.payload,
        },
      });

      break;
    case 'webview':
    case 'web_url':
      window.open(button.url, '_blank');
      break;
    case 'phone_number':
      window.open(`tel:${button.payload}`, '_blank');
      break;
    default:
      break;
  }
};
